<template>
    <div class="container">
        <div class="row justify-content-center g-0">
            <div class="col-md-6">
                <Section class="projects-header">
                    <p class="project-heading">Projects</p>
                </Section>
            </div>
        </div>
        <div class="row justify-content-center g-0">
            <div class="col-xs-12">
                
                <Timeline :events="events" />
            </div>
        </div>
    </div>
</template>

<script>
import Section from '@/components/Section';
import Timeline from '@/components/Timeline';

//data
import Events from '@/data/events';

export default {
    name: "Projects",
    components: {
        Section,
        Timeline
    },
    computed: {
        events() {
            return Events.events;
        }
    }
}
</script>

<style lang="scss">
    .projects-header {
        margin-top: 36px;
        margin-bottom: 36px;
        text-align: center;

        /* transpiles to .projects-header .section, which is a more specific match */
        .section {
            padding: 6px;
        }

        .project-subheading {
            font-size: 18px;
            font-weight: 700;
            line-height: 1.2;
            color: rgba(black, 0.25);
        }
        .project-heading {
            font-size: 48px;
            font-weight: 800;
            line-height: 1.2;
            color: var(--text);
        }
    }
</style>