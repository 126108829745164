/*export default {
    events: [
        {
            date: "August 2020",
            title: "A fine tital",
            description: "I researched, tested, and deployed a React Redux application that would perform damage calculations for the game Minecraft based on player weapons, armor, enchantments, and effects. Includes graphs, numerical calculations, simulator, and more. ",
            link: "",
            images: ["avatar", "logo"],
            icons: ["redux", "sass"],
        },
        {
            date: "January 2021",
            title: "A fine tital 2",
            description: "I planned, prototyped, and deployed a React application that would permit Youtube Playlist Shuffling with weights, specifying start and end times for videos, and duplicating videos. Lazy loads playlists with thousands of videos, supports searching through records, and saves data to web storage.",
            link: "",
            images: ["logo"],
            icons: ["react-native", "nodejs"],
        },
        {
            date: "December 2020",
            title: "Just text here",
            description: "I learned and utilized Google Apps Script to embed an image's pixel colors into a block of text such that each character would be highlighted with the color of about one pixel in the image.",
            link: "",
            icons: ["google-docs"],
        },
        
    ]
}
*/
export default {
    events: [
        {
            "date": "December 15, 2022",
            "title": "Wordnet",
            "icons": "python angularjs",
            "images": "wordnet1",
            "link": "https://www.qxbytes.com/wordnet/",
            "description": "Visual analysis of words and their hyper/hyponym relationships. Give the backend a moment to load."
        },
        {
            "date": "July 20, 2022",
            "title": "Stocks",
            "icons": "python redux",
            "images": "stocks1",
            "link": "https://www.qxbytes.com/stockings/",
            "description": "Obligatory stock picking/monitoring app to show how much I LOVE finance."
        },
        {
            "date": "February 15, 2022",
            "title": "Dyneye",
            "icons": "python",
            "images": "dyneye1 dyneye2 dyneye3 dyneye4 dyneye5 dyneye6",
            "description": "Collects thousands of map data images from a Minecraft server in order to save and replay the entire build history of the world in video format. High-activity areas identified could be reconstructed from map tiles to quickly view coordinates of interest. Basically, a spy satellite but for Minecraft servers."
        },
        {
            "date": "August 8, 2021",
            "title": "Vue.js Shenanigans",
            "icons": "vue-js",
            "link": "https://www.blockeley.com",
            "description": "Working on Blockeley's website; also worked on this one."
        },
        {
            "date": "July 15, 2021",
            "title": "Virtual Machine Madness",
            "icons": "linux",
            "description": "I am now a \"Linux\" \"Sys\" \"Admin\" after playing around with VMs for a few weeks."
        },
        {
            "date": "July 1, 2021",
            "title": "Minecraft Damage Calculator/ Simulator",
            "icons": "redux sass",
            "images": "calc1 calc2 calc3 calc4 calc5 calc6 calc7",
            "link": "https://www.qxbytes.com/combat/",
            "description": "Ever wanted to know how many hits with a sharpness III diamond sword it would take to kill your friend (in Minecraft, for legal reasons) without actually stabbing them? no? Well whether you like it or not, the answer is in here somewhere."
        },
        {
            "date": "June 14, 2021",
            "title": "Tunes",
            "icons": "react-native nodejs",
            "images": "tunes1 tunes2 tunes3 tunes4",
            "link": "https://www.qxbytes.com/tunes/",
            "description": "Spotify? ❌ Itunes? ❌ Make your own app so that you can listen to your music with all the features you ever wanted? ✅"
        },
        {
            "date": "May 20, 2021",
            "title": "Automatic Image Highlighter",
            "icons": "google-docs",
            "images": "highlighter",
            "link": "https://www.github.com/QxBytes/image-highlighter/",
            "description": "Strategically highlighting a dense patch of text in a google doc to reveal my teacher's face. Honestly it's probably better if you look at an example."
        },
        {
            "date": "November 1, 2020",
            "title": "Sketch",
            "icons": "javascript bootstrap",
            "images": "sketch sketch2 sketch3 sketch4",
            "link": "https://www.qxbytes.com/sketch/",
            "description": "Adobe Illustrator rip-off."
        },
        {
            "date": "July 1, 2020",
            "title": "Tunetube",
            "icons": "react-native css3 html-5 mongodb nodejs youtube",
            "images": "tunetube",
            "link": "https://www.qxbytes.com/tunetube/",
            "description": "Sequel to my playlist saver, now without exposing my API keys!"
        },
        {
            "date": "May 1, 2020",
            "title": "Knockback Ninja",
            "icons": "c-sharp",
            "images": "knockback",
            "link": "https://www.qxbytes.com/knockbackninja/",
            "description": "Get pelted by rocks while staying on the platform for fun and glory, or more concisely: get stoned."
        },
        {
            "date": "March 1, 2020",
            "title": "Genome Process 2.0",
            "icons": "java",
            "images": "genomeplus",
            "description": "Follow up to my protein synthesis application, now with graphics that look like a scrapbook made in MS Paint."
        },
        {
            "date": "January 1, 2020",
            "title": "HAPI Audit",
            "icons": "java",
            "images": "hirs1 hirs hirs2 hirs3 hirs4 hirs5 hirs6",
            "description": "Sus auction bid detector"
        },
        {
            "date": "December 1, 2019",
            "title": "Player Statue Builder",
            "icons": "java",
            "images": "player",
            "link": "https://www.spigotmc.org/resources/playerstatuebuilderx.73715/ ",
            "description": "Plugin that automatically generates Minecraft skin statues in your world. Being conceited has never been easier."
        },
        {
            "date": "November 1, 2019",
            "title": "HAPI Data Collection",
            "icons": "java",
            "images": "analysis5 analysis4 isolate1 isolate4",
            "description": "Program that detected hypixel skyblock auctions in real-time that were expiring below market value, allowing a user to \"flip\" those auctions for a profit. Why YES, I am winning at this game made for children."
        },
        {
            "date": "September 1, 2019",
            "title": "Cats",
            "icons": "javascript",
            "images": "cats",
            "link": "https://www.khanacademy.org/computer-programming/cats/5267405952221184/",
            "description": "I answered the question: Can a cat have 3000 descendants in 18 months? by creating a simulation of cats procreating over time."
        },
        {
            "date": "June 20, 2019",
            "title": "Youtube API Playlist Saver",
            "icons": "javascript css3 html-5 youtube",
            "images": "sorter sorter2",
            "description": "Sorts a Youtube playlist's videos by viewcount because this wasn't a thing back then."
        },
        {
            "date": "June 1, 2019",
            "title": "PS Image Processor",
            "icons": "adobe-photoshop",
            "description": "Automatically apply filters to make images look good."
        },
        {
            "date": "March 31, 2019",
            "title": "CSF Point Update Automation",
            "icons": "google-docs",
            "description": "In our dystopian society, this program checks your sign in data and matches it to your user data."
        },
        {
            "date": "February 1, 2019",
            "title": "File Searcher",
            "icons": "java",
            "images": "log",
            "link": "https://github.com/QxBytes/mc-log-analyzer",
            "description": "Find the most sus things in your Minecraft logs in an instant."
        },
        {
            "date": "January 1, 2019",
            "title": "Gravity Attack",
            "icons": "c-sharp",
            "images": "gravity gravity2",
            "link": "https://qxbytes.com/gravityattack/",
            "description": "Using physics to make flying saucer game."
        },
        {
            "date": "June 1, 2018",
            "title": "Oracle Certified Professional",
            "icons": "oracle",
            "description": "Acquired the Oracle Certified Professional, Java SE 7 certificate."
        },
        {
            "date": "May 20, 2018",
            "title": "Space Adventure",
            "icons": "java",
            "images": "space",
            "description": "Scuffed space platformer for a class project."
        },
        {
            "date": "April 6, 2018",
            "title": "TerraVect Boss Rush",
            "icons": "java",
            "images": "bossrush1 bossrush2 bossrush3",
            "link": "https://github.com/QxBytes/terravect-boss-rush",
            "description": "Used math, file manipualtion, and world generation to make this bullet-hell/building game monstrosity."
        },
        {
            "date": "March 25, 2018",
            "title": "Isoland",
            "icons": "javascript",
            "images": "isoland",
            "link": "https://www.khanacademy.org/computer-programming/isoland/6684397805797376",
            "description": "Pseudo-isometric world builder."
        },
        {
            "date": "June 1, 2017",
            "title": "TerraVect",
            "icons": "java",
            "images": "terravect terravect2",
            "description": "Terrain generator for TerraVect."
        },
        {
            "date": "May 1, 2017",
            "title": "Interactive Periodic Table",
            "icons": "java",
            "images": "periodic periodic2",
            "description": "It's like googling for periodic table elements, but worse."
        },
        {
            "date": "December 1, 2016",
            "title": "Beeper Studio 17",
            "icons": "java",
            "images": "beeper",
            "description": "Tone synthesizer and beeping song creator."
        },
        {
            "date": "October 1, 2016",
            "title": "JavaSoftOffice",
            "icons": "java",
            "images": "javasoft",
            "description": "Microsoft office users fear me."
        },
        {
            "date": "July 1, 2016",
            "title": "System Dodge",
            "icons": "java",
            "images": "system system2",
            "description": "Bullet hell game where in the original version, you wagered the health of your computer (gone wrong, not clickbait)."
        },
        {
            "date": "May 1, 2016",
            "title": "Unicode Art",
            "icons": "java",
            "images": "unicode unicode2 unicode3 unicode4",
            "description": "Translates your image into not-ASCII text art."
        },
        {
            "date": "March 1, 2016",
            "title": "Genome Process ",
            "icons": "java",
            "images": "genome",
            "description": "DNA base pairing program but now matches triplets up."
        },
        {
            "date": "December 16, 2015",
            "title": "Internet Regulat0r",
            "icons": "javascript",
            "images": "internet",
            "link": "https://www.khanacademy.org/computer-programming/internet-regulat0r/5844749586661376",
            "description": "Winning submission for the Internet 101 Contest."
        },
        {
            "date": "November 11, 2015",
            "title": "The Catalog",
            "icons": "javascript",
            "images": "catalog",
            "link": "https://www.khanacademy.org/computer-programming/the-catalog/4990951374848000",
            "description": "Winning submission for the Halloween Costume Contest."
        },
        {
            "date": "September 18, 2015",
            "title": "Celebration Games",
            "icons": "javascript",
            "images": "celebration",
            "link": "https://www.khanacademy.org/computer-programming/celebration-hyper-games/5010633622945792",
            "description": "Winning submission for the Celebration! Contest."
        },
        {
            "date": "August 1, 2015",
            "title": "Punnett Square Generator",
            "icons": "java",
            "images": "punnett",
            "description": "Remember Punnett Squares? Here's a simulator."
        },
        {
            "date": "July 1, 2015",
            "title": "Dungeon Drillers",
            "icons": "java",
            "images": "dungeon",
            "description": "Smol exploration game."
        },
        {
            "date": "March 1, 2015",
            "title": "QPong",
            "icons": "java",
            "images": "qpong",
            "description": "Revolutionizing pong by adding a SECOND player-controlled paddle. Atari not gonna know what hit them."
        },
        {
            "date": "February 1, 2015",
            "title": "Geo Live",
            "icons": "java",
            "images": "geolive",
            "description": "Just a square living off the empty land."
        },
        {
            "date": "June 1, 2014",
            "title": "RPG",
            "icons": "java",
            "images": "rpg",
            "description": "Standard text-based rpg that everyone makes at some point."
        }
    ]
}