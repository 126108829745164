<template>
    <div class="event">
        <div class="event-header">
            <span class="material-icons">        
                <a :id="identifier" :href="getIdentifierLink()" class="link-section">link</a>
            </span>
            <p v-if="date" class="event-date">
                {{ date }}
            </p>
            <p v-if="title" class="event-heading">
                {{ title }}
            </p>
            <p class="event-text">
                {{ description }}
            </p>
        </div>
        <FullImage v-if="validImage" :imageArr="getFiles()" title="MORE" />
        <div class="corner-flaire">
            <Ico v-for="icon in icons" size="sm" :dataObj="icon" :key="title + ' ' + icon"/>
            <a v-if="link" :href="link" target="_blank">
                <span class="material-icons">open_in_new</span>
            </a>
        </div>
        <div class="link-flaire">
        </div>
    </div>
</template>

<script>
import FullImage from '@/components/FullImage';
import Ico from '@/components/Ico';

export default {
    name: "TimelineItem",
    components: {
        FullImage,
        Ico
    },
    props: [
        "dataObj"
    ],
    computed: {
        date() {
            return this.dataObj.date;
        },
        title() {
            return this.dataObj.title;
        },
        identifier() {
            return this.dataObj.title.replaceAll(/[\W_]+/g,"-")
        },
        description() {
            return this.dataObj.description;
        },
        link() {
            return this.dataObj.link;
        },
        images() {
            return this.dataObj.images;
        },
        icons() {
            return this.dataObj.icons;
        },
        validImage() {
            /* by converting to a string, you get the WORD undefined! */
            return this.images && this.images.length > 0 && this.images[0] && 
            this.images[0] !== "undefined";
        },
        headerImage() {
            return this.images()[0];
        },
    },
    methods: {
        getFile(image) {
            return '/assets/projects/' + image + '.png';
        },
        getIdentifierLink() {
            return 'projects#' + this.identifier;
        },
        getFiles() {
            return this.images.map( (obj) => this.getFile(obj) );
        }
    }
}
</script>

<style lang="scss">
    .event {
        position: relative;
        
        padding: 24px;

        border: solid 1px var(--muted);
        border-radius: 12px;

        color: var(--text);
        background-color: var(--panel);

        transition: border .3s ease;

        .event-header {
            display: flex;
            flex-direction: column;

            overflow-wrap: break-word;

            p.event-date {
                font-size: 24px;
                font-weight: 700;
                line-height: 1.2;
                color: rgba(black, 0.25);

                margin-bottom: 0px;
            }

            p.event-heading {
                font-size: 36px;
                font-weight: 800;
                line-height: 1.2;
                color: var(--text);

                text-shadow: 4px 4px rgba(var(--accent-color)F0);

                margin-top: 0px;
                margin-bottom: 0px;
            }

            p.event-text {
                font-size: 16px;
                font-weight: 300;
                
                margin-bottom: 24px;

            }
        }

        .corner-flaire {
            position: absolute;
            bottom: 6px;
            right: 6px;

            padding: 6px;

            border-radius: 12px;

            display: flex;

            background-color: var(--panel);

            span {
                font-size: 24px;
            }
        }

        
        .material-icons {
            a {
                font-size: 24px;
            }
        }

        @media (min-width: 768px) {
            text-align: right;
            right: calc(50% + 3rem);
            margin-top: -72px;
            margin-bottom: -72px;

            &:nth-of-type(2n) {
                flex-direction: row-reverse;

                text-align: left;
                left: calc(50% + 3rem);
            }
        }
        @media (max-width: 768px) {
            text-align: left;
            left: 2rem;

            margin-top: 48px;
            margin-bottom: 48px;
        }
    }
    .event:hover {
        border: solid 1px var(--accent);
    }

</style>