<template>
    <div class="timeline-wrapper">
        <div class="container"> 
            <div id="" class="row justify-content-center">
                <div class="col-lg-5 col-md-6 timeline">
                    <TimelineItem v-for="event in processedEvents" :key="event.date" :dataObj="event"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TimelineItem from '@/components/TimelineItem'

export default {
    name: "Timeline",

    components: {
        TimelineItem
    },

    props: [
        "events"
    ],

    computed: {
        processedEvents() {
            let processed = [];
            for (let evt of this.events) {
                /* Note: evt.icons is not a string initially-- convert it! */
                processed.push({
                    date: evt.date,
                    title: evt.title,
                    description: evt.description,
                    link: evt.link,
                    icons: ("" + evt.icons).split(' '),
                    images: ("" + evt.images).split(' ')
                });
            }
            
            return processed;
        }
    }
}
</script>

<style lang="scss">
    .timeline-wrapper {
        margin-top: 96px;
        margin-left: 16px;
        margin-right: 16px;
        margin-bottom: 96px;

        .timeline {
            position: relative;

            @media (min-width: 768px) {
                &::after {
                    content: "";
                    position: absolute;

                    top: 0;
                    bottom: 0;
                    left: calc(50% - .25rem + 1px);

                    border-left: .5rem dashed #505050;
                }
            }
            @media (max-width: 768px) {
                &::after {
                    content: "";
                    position: absolute;

                    top: 0;
                    bottom: 0;
                    left: 0;

                    border-left: .5rem dashed #505050;
                }
            }
        }
    }
</style>